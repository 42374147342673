import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ClosePhoto from '../../assets/svgs/close-photo.svg';
import { toast } from 'react-toastify';
import Spinner from '../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { uploadMultiple, uploadSingle } from '../../services';

interface Upload {
  name: string;
  image: string;
}

interface File {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  webkitRelativePath: string;
  lastModifiedDate: any;
}

const Upload = (): JSX.Element => {
  const navigate = useNavigate();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [upload, setUpload] = useState<Upload[]>([]);
  const [timeSpinner, setTimeSpinner] = useState<boolean>(false);
  const [data, setData] = useState<File[]>([]);
  const [handleDisabledButtonUpload, setHandleDisabledButtonUpload] =
    useState<boolean>(true);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChange = (e: { target: any }) => {
    if (e.target.files) {
      Array.from(e.target.files).map((selectedFile: any) => {
        if (selectedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onloadend = (e) => {
            if (e.target?.result) {
              setUpload((prev) => [
                ...prev,
                {
                  name: selectedFile.name,
                  image: e.target?.result?.toString() ?? '',
                },
              ]);
              setData((prev) => [...prev, selectedFile]);
            }
          };
        }
      });
    }
  };

  const removeImage = (indexImage: number) => {
    setUpload(
      upload.filter((res: Upload, index: number) => indexImage !== index),
    );
  };

  const handleUploadFiles = () => {
    if (upload.length === 0) {
      return toast.error('Selecione uma imagem para enviar');
    }
    setTimeSpinner(true);
    setHandleDisabledButtonUpload(true);
    setTimeout(() => {
      if (upload.length === 1) {
        uploadSingle(data[0])
          .then(() => {
            toast.success('Upload realizado com sucesso');
            setUpload([]);
            setTimeSpinner(false);
            setHandleDisabledButtonUpload(false);
          })
          .catch(() => {
            setTimeSpinner(false);
            toast.error('Erro ao realizar o upload');
            setHandleDisabledButtonUpload(false);
          });
      } else {
        uploadMultiple(data)
          .then(() => {
            toast.success('Upload realizado com sucesso');
            setUpload([]);
            setTimeSpinner(false);
            setHandleDisabledButtonUpload(false);
          })
          .catch(() => {
            setTimeSpinner(false);
            setHandleDisabledButtonUpload(false);
            toast.error('Erro ao realizar o upload');
          });
      }
    }, 2000);
  };

  useEffect(() => {
    if (upload.length > 0) {
      setHandleDisabledButtonUpload(false);
    } else {
      setHandleDisabledButtonUpload(true);
    }
  }, [upload]);

  return (
    <Container>
      <Content>
        <DropContainer>
          <input
            type={'file'}
            accept="image/*"
            multiple
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <UploadMessage>
            Arquivos no formato de imagem JPG, PNG, GIF
          </UploadMessage>
          <ButtonUpload onClick={() => handleClick()}>
            Suba seus arquivos aqui.
          </ButtonUpload>
        </DropContainer>
        {upload.length > 0 && (
          <ImagesContainer>
            {upload.map((image, index) => {
              return (
                <Image src={image.image}>
                  <Img
                    src={ClosePhoto}
                    onClick={() => {
                      removeImage(index);
                    }}
                    data-dataid={'button_remove_image'}
                  />
                </Image>
              );
            })}
          </ImagesContainer>
        )}
        <ContainerStyled>
          <Button
            onClick={() => handleUploadFiles()}
            disabled={handleDisabledButtonUpload}
          >
            {timeSpinner ? <Spinner /> : 'Upload'}
          </Button>
          <Button onClick={() => navigate('./galeria')}>
            Adicionadas recentemente
          </Button>
        </ContainerStyled>
      </Content>
    </Container>
  );
};

export default Upload;

const Container = styled.main`
  margin: 0 auto;
`;

const Content = styled.section`
  max-width: 580px;
  background: #ffffff;
  padding: 42px 32px 32px 32px;
  text-align: center;
  position: relative;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #707175;
`;

const ContainerStyled = styled.div`
  margin-top: 40px;
`;

const UploadMessage = styled.p`
  color: #999;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 40px;
  /* color: #000000; */
`;

const DropContainer = styled.div`
  border: 1px dashed #ddd;
  border-radius: 4px;
  transition: height 0.2s ease;
  padding-bottom: 40px;
  width: 100%;
  background: #f9fafc;
`;

const ButtonUpload = styled.button`
  padding: 8px 16px;
  background: #ff6b00;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 5px;
  margin-top: 40px;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  background-color: ${(props) => props.theme.colors.primary};
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 49px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: ${(props) => props.theme.text.title.fontFamily};
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 8px;
  transition: 0.3s;
  margin-top: 12px;
  :hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Image = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 130px;
  height: 130px;
  border-radius: 6px;
  margin: 10px;
  animation-name: fade;
  animation-duration: 1s;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
