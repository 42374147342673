import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useUnprotectedPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const date = new Date();
    const dateLocal = date.toLocaleString('pt-BR');
    if (token && token !== null && token > dateLocal) {
      navigate('/');
    }
  }, [navigate]);
};

export default useUnprotectedPage;
