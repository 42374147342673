import styled, { css } from "styled-components/macro";
import { useState, useEffect } from "react";

type PaginationProps = {
  isActive?: boolean;
};

type Props = {
  pages: number;
  pg: number;
  setPg: (pg: number) => void;
  lastPage: number;
  total: number;
};

const Pagination = ({ pages, pg, setPg, lastPage, total }: Props): JSX.Element => {
  const [showResults, setShowResults] = useState<boolean>(false);
  const [skipButton, setSkipButton] = useState<boolean>(true);

  const handlePaginate = (props: string) => {
    if (props === "previous") {
      if (pg >= 1 && pg < total) {
        setPg(pg - 1);
      }
    } else if (props === "next") {
      if (pg < lastPage && pg >= 1) {
        setPg(pg + 1);
      }
    }
  };

  useEffect(() => {
    pg >= 3 ? setShowResults(true) : setShowResults(false);

    if (lastPage !== 0) {
      pg < lastPage - 3 ? setSkipButton(true) : setSkipButton(false);
    }
    if (lastPage < 5) {
      setShowResults(false);
    }
  }, [pg]);

  const handleSkipThreeIndexes = () => {
    const skipThreeIndexes = pg;
    setPg(skipThreeIndexes + 3);
  };

  const handleBackThreeIndexes = () => {
    const backThreeIndexes = pg;
    setPg(backThreeIndexes - 3);
  };

  return (
    <DivFlex>
      <PageList>
        {pg !== 0 && (
          <Paginate onClick={() => handlePaginate("previous")}>
            <i className="fas fa-angle-left"></i>
          </Paginate>
        )}
        {pg > 3 && <Paginate onClick={() => setPg(1)}>1</Paginate>}
        {showResults ? <Paginate onClick={handleBackThreeIndexes}>...</Paginate> : null}
        {Array.from(Array(pages), (item, index) => {
          if (index + 1 === pg - 1 || index + 1 === pg || index + 1 === pg + 1 || index + 1 === pg + 2) {
            return (
              <Paginate value={index} onClick={(e) => setPg(Number(e.currentTarget.value))} isActive={index == pg}>
                {index + 1}
              </Paginate>
            );
          }
        })}
        {skipButton && <Paginate onClick={handleSkipThreeIndexes}>...</Paginate>}
        {!(pg >= lastPage - 2) && (
          <Paginate isActive={pg === lastPage} onClick={() => setPg(lastPage)}>
            {lastPage}
          </Paginate>
        )}
        {!(pg === lastPage) && (
          <Paginate onClick={() => handlePaginate("next")}>
            <i className="fas fa-angle-right"></i>
          </Paginate>
        )}
      </PageList>
    </DivFlex>
  );
};

const DivFlex = styled.div`
  display: flex;
`;
const PageList = styled.ul`
  margin: 0;
  padding: 0;
`;

const Paginate = styled.button<PaginationProps>(
  ({ theme, isActive }) => css`
    box-shadow: 0px 1px 0px #8798AD;
    background-color: #F4F4F4;
    color: #FF6B00;
    font-size: 16px;
    font-family: "Rubik", sans-serif;
    line-height: 24px;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
    :nth-last-child(n + 2) {
      margin-right: 28px;
    }

    &:hover {
      color: #FF6B00;
    }
    &:focus {
      border: 1px solid #8798AD;
    }
  `
);
export default Pagination;
