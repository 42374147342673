import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useProtectedPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || token == null) {
      navigate('/login');
    }
    const date = new Date();
    const dateLocal = date.toLocaleString('pt-BR');
    if (token && token <= dateLocal) {
      localStorage.removeItem('token');
      toast.error('Sua sessão expirou, faça login novamente');
      navigate('/login');
    }
    
  }, [navigate]);
};

export default useProtectedPage;
