import Upload from '../../components/Upload/Upload';
import styled from 'styled-components';
import useProtectedPage from '../../hooks/useProtectedPage';

const Home = (): JSX.Element => {
  useProtectedPage();
  return (
    <Main>
      <Content>
        <BackgroundComponent />
        <Title>Suba suas fotos agora mesmo!</Title>
        <Container>
          <Upload data-testid={"upload_container"} />
        </Container>
      </Content>
      <Footer>
        <span>Um produto Máquina do Bem</span>
      </Footer>
    </Main>
  );
};
export default Home;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  background: #FF6B00;
  padding-bottom: 48px;
  overflow-x: hidden;

  /*   @media (max-width: 1100px) {
      background-size: cover;
   } */
`;

const Content = styled.div`
  position: relative;
  height: 100vh;
`;

const BackgroundComponent = styled.div`
  position: absolute;
  width: 150%;
  height: 60%;
  left: -80px;
  top: 230px;
  background: #FFFFFF;
  border: 1px solid #e6eaf6;
  transform: rotate(-10.92deg);
  overflow-y: hidden;
  @media (max-width: 749px) {
    top: 280px;
  }

  @media (max-width: 657px) {
    left: -80px;
    top: 290px;
  }

  @media (max-width: 499px) {
    left: -60px;
    top: 180px;
    height: 70%;
  }

  @media (max-width: 307px) {
    height: 70%;
    top: 240px;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 100px;
  position: relative;
  z-index: 999;
  color: #fff;
  @media (max-width: 500px) {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;

  span {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    color: white;
  }

  @media (max-width: 767px) {
    margin-top: 150px;
  }
`;
