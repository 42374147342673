import Cleave from 'cleave.js/react'
import { CleaveOptions } from 'cleave.js/options'
import { Fragment, InputHTMLAttributes } from 'react'
import styled, { css, CSSProperties } from 'styled-components'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
   label?: string
   error?: string | boolean
   wrapperStyle?: CSSProperties
   component?: JSX.Element
   mask?: CleaveOptions
   renderLabel?: (props: InputProps) => JSX.Element
   renderInput?: (props: InputProps) => JSX.Element
}

const PasswordInput = (inputProps: InputProps): JSX.Element => {
   const {
      label,
      wrapperStyle,
      error,
      mask,
      renderInput,
      renderLabel,
      ...props
   } = inputProps

   const renderEntry = () => {
      if (renderInput) {
         return renderInput(inputProps)
      }

      if (mask) {
         return <StyledMaskInput error={error} options={mask} {...props} />
      }

      return <StyledInput error={error} {...props} />
   }

  return (
    <Wrapper style={wrapperStyle}>

      {renderLabel ? renderLabel(inputProps) : label && <Label>{label}</Label>}

      {renderEntry() }

      {error && (
        <Fragment>
          <FormFeedback>{error}</FormFeedback>
        </Fragment>
      )}
    </Wrapper>
  );
};

export const InputWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`

export const InputRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
`
const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`

const Label = styled.label(
   () => css`
      font-family: 'Rubik', sans-serif;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      color: ${(props) => props.theme.colors.colorNeutralLight};
   `,
)

const InputStyle = css<InputProps>`
   padding: 20px !important;
   border-radius: 8px;
   border: 1px solid #bcc4de;
   color: ${(props) => props.theme.colors.colorNeutralLight};
   font-family: ${(props) => props.theme.text.description};

   ::placeholder {
      color: #bcc4de;
   }

   ${(props) =>
      props.error &&
      css`
         border-color: #eb5230;
      `}
`

const StyledInput = styled.input<InputProps>`
   ${InputStyle};
`

const StyledMaskInput = styled(Cleave)`
   ${InputStyle};
`

const FormFeedback = styled.div(
  (theme) => css`
    width: 100%;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #eb5230;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
   margin-top: 20px;
   padding-top: 20px;
    
  `
);
export default PasswordInput;
