export const Theme = {
    text: {
      title: {
        fontFamily: 'Rubik',
        fontWeight: '500'
      },
      description: {
        fontFamily: 'Rubik',
        fontWeight: '400'
      },
    },
    colors: {
      primary: '#FF6B00',
      black: '#000000',
      white: '#FFFFFF',
      colorNeutralLight: '#8798AD',
      brand: {
        primary: '#584afe'
      },
    },
    components: {
      select:{
        borderColor: '#bcc4de'
      },
      buttonPrimary: {
        backgroundColor: '#FF6B00',
        color: '#FFFFFF',
        border: '1px solid #ededed'
     }
    }
  }