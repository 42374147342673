import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes/Router';
import { Theme } from './styles/theme';

const App = (): JSX.Element => {
  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </>
  );
};

export default App;
