import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

const Loading: React.FC = () => {
   return (
      <LoadingContainer>
         <ReactLoading type="spin" color='#FF6B00' height={70} width={70} />
      </LoadingContainer>
   )
}

export default Loading

const LoadingContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`
