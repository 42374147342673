import styled, { css, ThemeProps } from 'styled-components';

type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  onClick,
}: CheckboxProps) => {
  return (
    <Container
      checked={checked}
      disabled={disabled ?? false}
      onClick={() => (onClick && !disabled ? onClick() : {})}
    />
  );
};

const Container = styled.div(
  ({ checked, disabled, theme }: CheckboxProps & ThemeProps<any>) => css`
    //(Well) Arrumar a tipagem
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: ${checked ? theme.colors.primary : 'transparent'};
    position: relative;
    cursor: pointer;
    border: 1px solid ${checked ? 'transparent' : '#BCC4DE'};
    opacity: ${disabled ? 0.5 : 1};

    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2.5px 2.5px 0;
      transform: rotate(45deg);
    }
  `,
);

export default Checkbox;
