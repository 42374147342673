import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Galery from '../pages/Galery/Galery';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/galeria" element={<Galery />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
