import axios from 'axios';

export const getAll = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/list/all',
    );
    return response.data;
  } catch {
    throw new Error('Erro ao obter dados');
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/login',
      {
        email,
        password,
      },
    );
    return response;
  } catch {
    throw new Error('Erro ao realizar login!');
  }
};

export const uploadSingle = async (image: any) => {
  const form = new FormData();
  form.append('file', image);
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/upload/single',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch {
    throw new Error('Erro ao realizar upload!');
  }
};

export const uploadMultiple = async (array: any) => {
  const form = new FormData();
  array.map((image: any) => {
    form.append('file', image);
  });
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/upload/multiple',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch {
    throw new Error('Erro ao realizar upload!');
  }
};
